const OSS = require('ali-oss')
export const client = new OSS({
    accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
    accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
    region: 'oss-cn-qingdao',
    bucket: 'imagetru3d'
})
/**
 * 删除多个oss
 * @param urls 数组
 */
export const deleteOSSFile = async (urls: any) => {
    // 去除空数据
    urls = urls.filter((url: any) => url !== '')
    // 去掉网址
    urls = urls.map((url: any) => decodeURI(url.split('.com').pop()))
    // 如果空数据跳出
    if (urls.length === 0) return
    return await client.deleteMulti(urls, {quiet: true})
}
