import {MessageBox} from 'element-ui';
// 返回确认
export const goBackConfirm = (thenMethod?: any, catchMethod?: any) => {
    MessageBox.confirm('确认返回吗？您所填写的内容将会丢失！', {
        title: '返回提示'
    })
        .then(() => {
            thenMethod()
        }).catch(() => {
        if (catchMethod) {
            catchMethod()
        }
    })
}
export const commonAlert = (message: string, title: string, thenMethod?: any, catchMethod?: any) => {
    MessageBox.alert(message, {
        title: title
    })
        .then(() => {
            thenMethod()
        }).catch(() => {
        if (catchMethod) {
            catchMethod()
        }
    })
}
export const commonConfirm = (message: string, title: string, thenMethod?: any, catchMethod?: any) => {
    MessageBox.confirm(message, {
        title: title
    })
        .then(() => {
            thenMethod()
        }).catch(() => {
        if (catchMethod) {
            catchMethod()
        }
    })
}
