



























import {Component, Prop, Vue} from 'vue-property-decorator'
import config from '@/config'
import {client} from "@/utils/oss";

@Component
export default class BtnSingleUploader extends Vue {
  $refs!: { upload: any }
  @Prop({default: 1}) limit!: 1
  @Prop({default: true}) multiple!: boolean
  @Prop({default: ''}) data!: any
  @Prop() beforeUpload!: any
  @Prop() onSuccess!: any
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  version = config.version;
  tagName: any = ''
  // 进度条状态
  progressPercent: number = 0

  get showProgress() {
    return this.progressPercent > 0 && this.progressPercent < 100
  }

  async uploadFile(file: any) {
    // 获取要上传的文件
    const uploadFile = file.file
    const suffix = file.file.name.split('.').pop()
    // 获取扩展名
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/' + uuid + '.' + suffix
    let that = this;
    const res = await client.multipartUpload(path, uploadFile, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Encoding': 'utf-8',
        'x-oss-forbid-overwrite': 'true'
      },
      progress: function (p: any, checkpoint: any) {
        that.progressPercent = Math.floor(p * 100)
      }
    })
    return {
      res,
      file
    }
  }

  // // 上传成功
  // handleSuccess(response: any) {
  //   this.tagName = response.file.file.name
  //   let data = {
  //     fileName: response.file.file.name,
  //     index: response.file.data.$index,
  //     url: response.res.res.requestUrls[0].split('?').shift()
  //   }
  //   this.$emit('on-success', data)
  // }
}
