import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import {commonAlert} from "@/utils/confirm";

@Component
export default class BeforeUploadMixins extends Vue {
    // 判断手机端模型上传
    beforeModelUpload = (file: any) => {

        if (file.size === 0) { //没有上传
            return true;
        }

        const suffix = file.name.split('.').pop().toLocaleLowerCase()
        const isObj = suffix === 'obj'
        const isFbx = suffix === 'fbx'
        const isGlb = suffix === 'glb'
        if (isObj || isFbx || isGlb) {
            return true
        }
        commonAlert('手机端模型上传失败，仅支持格式为fbx、obj、glb的模型', '失败提示')
        return false
    }
    before3DModelUpload = (file: any) => {
        return true;
        // const suffix = file.name.split('.').pop()
        // const isObj = suffix === 'obj'
        // const isFbx = suffix === 'fbx'
        // if (isObj || isFbx) {
        //     return true
        // }
        // commonAlert('手机端模型上传失败，仅支持格式为fbx、obj的模型', '失败提示')
        // return false
    }

    // 判断图片上传
    beforeImageUpload(file: any) {
        const isImg = file.type.split('/').shift() === 'image'
        if (!isImg) {
            commonAlert('上传封面图只支持图片格式!', '失败提示')
            return false
        }
        // 获取URL
        const URL = window.URL || window.webkitURL
        // 创建图片对象
        const img = new Image()
        img.src = URL.createObjectURL(file)
        return (new Promise((resolve, reject) => {
            img.onload = () => {
                if (img.height / 4 * 3 === img.width) {
                    resolve(true)
                } else {
                    resolve(true)
                    /*commonAlert('封面图上传失败，仅宽高比为3:4的封面图!', '失败提示')
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()*/
                }
            }
        }))
    }

    //检测图片是否是gif上传
    beforeImageUploadGif(file: any) {

        const isJPG = file.type === 'image/gif'
        if (!isJPG) {

            this.$message.error('封面图片Gif只能是 gif 格式!');

            return false;

        }

        //判断大小
        let iisLt2k = file.size / 1024 < (1024 * 3) ? '1' : '0';
        /*if (iisLt2k === '0'){
            this.$message.error('图片上传失败，GIF图超过3M');
            return  false;
        }*/

        const isImg = file.type.split('/').shift() === 'image'
        if (!isImg) {
            commonAlert('上传封面图只支持图片格式!', '失败提示')
            return false
        }
        // 获取URL
        const URL = window.URL || window.webkitURL
        // 创建图片对象
        const img = new Image()
        img.src = URL.createObjectURL(file)
        return (new Promise((resolve, reject) => {
            img.onload = () => {
                if (img.height / 4 * 3 === img.width) { //宽 长 比 4:3
                    resolve(true)
                } else {
                    resolve(true)
                    /*commonAlert('图片上传失败，GIF图长宽比错误', '失败提示')
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()*/
                }
            }
        }))
    }
}


