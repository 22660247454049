





































import {Component, Prop, Vue} from "vue-property-decorator";
import config from '@/config'
import {client} from "@/utils/oss";
import {commonConfirm} from "@/utils/confirm";

@Component
export default class DialogUploader extends Vue {
  @Prop({
    default: () => {
    }
  }) data!: any
  @Prop() type!: | 'image' | 'video' | 'extend'
  @Prop() limit!: number
  @Prop() beforeUpload!: any
  @Prop() onSuccess!: any
  version: any = config.version
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  files: any = []
  accept = this.type === 'image' ? '.jpg,.png,.gif,.bmp,.jpeg,.tiff,.jfif' :
      this.type === 'video' ? '.mp4,.avi,.mov,.rmvb,.wmv' :
          this.type === 'extend' ? '.obj,.fbx' :
              '*'
  customColors: any = [
    {color: '#f56c6c', percentage: 20},
    {color: '#e6a23c', percentage: 40},
    {color: '#5cb87a', percentage: 60},
    {color: '#1989fa', percentage: 80},
    {color: '#6f7ad3', percentage: 100}
  ]

  get dialogVisible() {
    const percentage = this.files.filter((item: any) => item.percentage !== 100)
    return percentage.length !== 0;
  }

  cancelUpload() {
    commonConfirm('确定要取消上传吗？', '取消提示', () => {
      // 获取所有未上传完毕的数据
      const data = this.files.filter((item: any) =>
          item.percentage !== 100
      )
      if (data.length === 0) return
      data.forEach(async (item: any) => {
        const {uploadName} = item
        const {uploadId} = item
        await client.abortMultipartUpload(uploadName, uploadId);
      })
      this.files = []
    })
  }

  async uploadFile(file: any) {
    // 获取要上传的文件
    const uploadFile = file.file
    const suffix = file.file.name.split('.').pop()
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    // const path = 'uploads/limitght/' + uuid + '_' + uploadFile.name
    const path = 'uploads/light/' + uuid + '.' + suffix
    let nowFile: any = {
      fileName: uploadFile.name,
      percentage: 0,
      uploadId: '',
      uploadName: path
    }
    this.files.push(nowFile)
    const res = await client.multipartUpload(path, uploadFile, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Encoding': 'utf-8',
        'x-oss-forbid-overwrite': 'true'
      },
      progress: function (percentage: number, checkpoint: any, res: any) {
        nowFile.percentage = Math.floor(percentage * 100)
        if (checkpoint) {
          nowFile.uploadId = checkpoint.uploadId
        }
      }
    })
    return {
      res,
      file
    }
  }
}
